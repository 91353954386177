import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingStyled = styled.div.attrs(({ theme }) => ({
  color: theme.secondary,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;

  p {
    font-weight: 500;
    margin-top: 2rem;
  }

  svg {
    color: ${({ color }) => color};
  }
`;

const Loading = () => {
  return (
    <>
      <LoadingStyled>
        <FontAwesomeIcon icon={faCircleNotch} size="8x" className="fa-spin" />
        <p>Loading...</p>
      </LoadingStyled>
    </>
  );
};

export default Loading;
