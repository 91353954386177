import React from 'react';
import { Route } from 'react-router-dom';

import NavBar from '../navbar/NavBar';
import { AppRouteProps } from '../../utils/types';

const AppliedRoute: React.FunctionComponent<AppRouteProps> = ({
  component: C,
  appProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <NavBar appProps={appProps} />
          <C appProps={appProps} props={props} />
        </>
      )}
    />
  );
};

export default AppliedRoute;
