import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../styled/Button';
import styles from '../../styles/modal.module.scss';

interface SuccessProps {
  callback: any; // TODO: Discuss with Dakota
}

const Success = ({ callback }: SuccessProps) => {
  return (
    <>
      <div className={styles.loader}>
        <FontAwesomeIcon icon={faCheck} size="8x" color="#48bb78" />
        <h2>Success!</h2>
      </div>
      <Button modal onClick={callback}>
        Close
      </Button>
    </>
  );
};

export default Success;
