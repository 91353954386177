import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton, Button } from '../styled/Button';
import Illustration from './Illustration';

const DesktopHeaderStyled = styled.div`
  display: flex;

  svg {
    height: 600px;
    width: 100%;
    order: 1;
  }

  & > div {
    width: 100%;
    max-width: 600px;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 3rem;
      line-height: 1.125;
    }

    p {
      margin-top: 1rem;
      line-height: 1.5;
      max-width: 500px;
    }
  }

  .buttons {
    min-width: 500px;
    flex-direction: row;
    margin-top: 1rem;

    & > :first-child {
      margin-right: 1rem;
    }

    button {
      max-width: 200px;
    }
  }
`;

const DesktopHeader = ({ btnText, btnLink }: HomeHeaderProps) => {
  return (
    <DesktopHeaderStyled>
      <div>
        <h1>
          Access your coach.
          <br /> From Anywhere.
        </h1>
        <p>
          Vegatouch Cloud Services give you remote access to your connected
          coach no matter where you are. 
         </p> 
         <p>
            <b>
              Vegatouch cloud subscriptions can be purchased at fireflyint.com. 
              Receive a free 90-day trial with any subscription purchase. 
            </b>
        </p>
        {/* <div className="buttons">
          <Link to={btnLink}>
            <BlueButton>{btnText}</BlueButton>
          </Link>
          <Link to="/FAQ">
            <Button>FAQ</Button>
          </Link>
        </div> */}
      </div>
      <Illustration />
    </DesktopHeaderStyled>
  );
};

export default DesktopHeader;
