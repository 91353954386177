import React from 'react';
import { Route } from 'react-router-dom';

import NavBar from '../navbar/NavBar';
import Footer from '../footer/Footer';

const CatchAllRoute: React.FunctionComponent<AppRouteProps> = ({
  component: C,
  appProps,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <>
          <NavBar appProps={appProps} />
          <C appProps={appProps} props={props} />
          <Footer />
        </>
      )}
    />
  );
};

export default CatchAllRoute;
