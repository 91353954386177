import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faCreditCard,
  faMoon,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

import Header from './Header';
import Footer from './Footer';
import stripe from '../../utils/stripe';
import { UPDATE_PAYMENT_INFO } from '../../utils/queries';
import { Button, RedButton, IconButton } from '../styled/Button';
import { grid } from '../../styles/modal.module.scss';

const MenuButton = ({ title, callback, icon }) => {
  return (
    <IconButton modal onClick={callback}>
      <p>{title}</p>
      <div>
        <FontAwesomeIcon icon={icon} size="lg" color="#fff" />
      </div>
    </IconButton>
  );
};

const ManageUser = ({
  handleDashboard,
  handleLogout,
  showChangePassword,
  setShowModal,
  setStatus,
}) => {
  const history = useHistory();
  const [updatePaymentInfo] = useMutation(UPDATE_PAYMENT_INFO);

  const sendToCheckout = async () => {
    try {
      // Show loader
      setStatus('loading', true);

      // Get session ID from GQL
      const { data } = await updatePaymentInfo();

      // Redirect to checkout page
      await stripe.redirectToCheckout({
        sessionId: data.CollectPaymentInfoSession,
      });
    } catch (error) {
      alert(error.message.replace('GraphQL error: ', ''));
      setStatus('loading', false);
    }
  };

  const goToSupport = () => {
    // Redirect to support page
    history.push('/support');

    // Close modal
    setShowModal(false);
  };

  return (
    <>
      <div>
        <Header
          title="User Profile"
          closeable
          callback={() => setShowModal(false)}
          textOverride="Close"
        />
        <div className={grid}>
          {/* <IconButton onClick={toggleTheme}>
            <FontAwesomeIcon icon={faMoon} size="lg" color="#fff" />
            <p>Night Mode</p>
          </IconButton> */}
          <MenuButton
            title="Change Password"
            callback={() => showChangePassword(true)}
            icon={faLock}
          />
          <MenuButton
            title="Update Payment"
            callback={sendToCheckout}
            icon={faCreditCard}
          />
          <MenuButton
            title="Contact Support"
            callback={goToSupport}
            icon={faQuestionCircle}
          />
        </div>
      </div>

      <Footer>
        <Button modal onClick={handleDashboard}>
          Dashboard
        </Button>
        <RedButton modal onClick={handleLogout}>
          Logout
        </RedButton>
      </Footer>
    </>
  );
};

export default ManageUser;
