import React from 'react';

import Header from './Header';
import Footer from './Footer';
import { Button, RedButton } from '../styled/Button';
import PasswordReqs from '../signup/PasswordReqs';
import { useFormFields } from '../../hooks/hookslib';
import { changeUserPassword } from '../../utils/auth.ts';

// TODO: Change the alerts in this file to a onscreen error
const ChangePassword = ({ showChangePassword, setStatus }) => {
  const [fields, handleFieldChange] = useFormFields({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  const conditions = {
    length: fields.password.length >= 8,
    uppercase: fields.password.match(/[A-Z]/),
    lowercase: fields.password.match(/[a-z]/),
    number: fields.password.match(/[0-9]/),
  };

  const changePassword = async event => {
    event.preventDefault();

    // Display loader
    setStatus('loading', true);

    // Check to make sure password and confirm password match
    if (fields.password !== fields.confirmPassword) {
      alert('New passwords do not match.');
      return setStatus('loading', false);
    }

    try {
      // Change password with provided fields
      await changeUserPassword({ ...fields, newPassword: fields.password });

      // Hide loader
      setStatus('loading', false);

      // Hide change password form
      showChangePassword(false);

      // Show success
      return setStatus('success', true);
    } catch (e) {
      // Hide loader
      setStatus('loading', false);

      if (e.code === 'InvalidParameterException')
        return alert('Old password is incorrect.');

      return alert(e.message);
    }
  };

  return (
    <>
      <form>
        <Header title="Change Password" closeable={false} />
        <input
          placeholder="old password"
          type="password"
          id="oldPassword"
          value={fields.oldPassword}
          onChange={handleFieldChange}
        />
        <input
          placeholder="new password"
          type="password"
          id="password"
          value={fields.password}
          onChange={handleFieldChange}
        />
        <input
          placeholder="confirm password"
          type="password"
          id="confirmPassword"
          onChange={handleFieldChange}
          value={fields.confirmPassword}
        />
        <PasswordReqs conditions={conditions} />
      </form>

      <Footer>
        <Button modal onClick={changePassword}>
          Change Password
        </Button>
        <RedButton modal onClick={() => showChangePassword(false)}>
          Cancel
        </RedButton>
      </Footer>
    </>
  );
};

export default ChangePassword;
