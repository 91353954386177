import { createGlobalStyle } from 'styled-components';

import { GlobalTheme } from '../utils/types';

export default createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }: GlobalTheme) => theme.primary};
    color: ${({ theme }: GlobalTheme) => theme.secondary};
    transition: all 0.25s linear;
  }`;
