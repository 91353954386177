import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton, Button } from '../styled/Button';
import Illustration from './Illustration';

const TabletHeaderStyled = styled.div`
  margin: 1rem 0;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-template-areas:
    'a'
    'c'
    'd'
    'b';

  svg {
    grid-area: b;
    height: 500px;
    width: 100%;
  }

  h1 {
    grid-area: a;
    line-height: 1.125;
    font-size: 3rem;
  }

  p {
    grid-area: c;
    margin: auto;
    margin-top: 1rem;
    line-height: 1.5;
    max-width: 500px;
  }

  .buttons {
    grid-area: d;
    margin: auto;
    margin-top: 2rem;
    max-width: calc(400px + 1rem);
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    & > :first-child {
      margin-right: 1rem;
    }

    button {
      max-width: 200px;
    }
  }
`;

const TabletHeader = ({ btnText, btnLink }: HomeHeaderProps) => {
  return (
    <TabletHeaderStyled>
      <h1>
        Access your coach.
        <br /> From Anywhere.
      </h1>
      <Illustration />
        <p>
          Vegatouch Cloud Services give you remote access to your connected
          coach no matter where you are. 
         <br />
         <br />
            <b>
              Vegatouch cloud subscriptions can be purchased at fireflyint.com. 
              Receive a free 90-day trial with any subscription purchase. 
            </b>
        </p>
      {/* <div className="buttons">
        <Link to={btnLink}>
          <BlueButton>{btnText}</BlueButton>
        </Link>
        <Link to="/FAQ">
          <Button>FAQ</Button>
        </Link>
      </div> */}
    </TabletHeaderStyled>
  );
};

export default TabletHeader;
