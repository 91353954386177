import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';

export default ({ accessToken, idToken }: Tokens): ApolloClient<any> => {
  // Create a REST link
  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API}/graphql`,
  });

  // Pass in the HTTP Link and create a authorized request
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        identity: idToken || '',
      },
    };
  });

  // Return client back to App.ts
  return new ApolloClient({
    cache: new InMemoryCache(),
    // Fix for: https://github.com/apollographql/apollo-link/issues/538
    // @ts-ignore
    link: authLink.concat(httpLink),
  });
};
