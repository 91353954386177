import styled from 'styled-components';

interface ButtonProps {
  modal?: boolean;
}

interface SubscriptionProps extends ButtonProps {
  status: string;
}

/**
 * White button with black font
 */
export const Button = styled.button<ButtonProps>`
  border: none;
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.primary};
  height: 3rem;
  border-radius: 0.5rem;
  font-size: 16px;
  width: 100%;
  transition: background 0.1s ease-in;

  /* modal=true as a prop will allow it to extend width 100% */
  @media (min-width: 421px) {
    max-width: ${({ modal }) => (modal ? '400px' : '300px')};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const IconButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.primary};
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06); */
  padding: 0 0.75rem;

  div {
    width: 30px;
    display: flex;
    justify-content: center;

    svg {
      color: ${({ theme }) => theme.secondary};
    }
  }

  p {
    color: ${({ theme }) => theme.secondary} !important;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const BlueButton = styled(Button)`
  background-color: var(--ff-blue-1);
  color: #fff;

  &:hover {
    background-color: var(--ff-blue-1);
    cursor: pointer;
  }
`;

export const RedButton = styled(Button)`
  background-color: ${({ theme }) => theme.primary};
  border: 1px solid var(--ff-red);
  color: var(--ff-red);

  &:focus {
    background: var(--ff-red);
    color: var(--ff-white);
    cursor: pointer;
  }
`;

/**
 * Button that doesn't have a direct inverse on theme switch
 *
 * Light mode: white button with black font & border
 * Dark mode: charcoal button with gray5 font & border
 */
export const SecondaryButton = styled(Button).attrs(({ theme }) => {
  const { isDark, charcoal, gray5, white, black } = theme;

  return {
    background: isDark ? charcoal : white,
    color: isDark ? gray5 : black,
  };
})`
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
`;

const returnColor = ({ theme, status }: { theme: any; status: string }) => {
  const { red, green, yellow } = theme;
  let color;

  if (status === 'not active') {
    color = red;
  } else if (status === 'cancelled') {
    color = yellow;
  } else {
    color = green;
  }

  return color;
};

/**
 * Button that will be based on subscription status
 *
 * Active: green button
 * Not Active: red button
 * Cancelled: yellow button
 */
export const SubscriptionButton = styled.button<SubscriptionProps>`
  border: none;
  background-color: ${props => returnColor(props)};
  color: var(--ff-white);
  height: 2rem;
  border-radius: 2rem;
  font-size: 14px;
  font-weight: 600;
  width: 90px;
  transition: background 0.1s ease-in;

  &:hover {
    cursor: pointer;
  }
`;
