import gql from 'graphql-tag';

// Mutation to add a sub with an existing payment method
export const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($plan: PaymentPlans!, $deviceID: ID!) {
    CreateDeviceSubscription(input: { plan: $plan, deviceID: $deviceID }) {
      subscription {
        ID
        cancelled
      }
    }
  }
`;

// Mutation to register a coach
export const COACH_REGISTRATION = gql`
  mutation register($ID: ID!) {
    ClaimDevice(ID: $ID) {
      ID
      subscription {
        ID
        expiration
        annual
        cancelled
      }
    }
  }
`;

// Query to get a user's device and sub info
export const USER_INFO = gql`
  query getInfo {
    Customer {
      devices {
        ID
        subscription {
          ID
          expiration
          annual
          cancelled
        }
      }
    }
  }
`;

// Mutation to remove a user's device and sub info
export const REMOVE_DEVICE = gql`
  mutation removeDevice($ID: ID!) {
    UnclaimDevice(ID: $ID)
  }
`;

// Mutation to change a device's subscription cycle
export const UPDATE_BILLING_CYCLE = gql`
  mutation updateBillingCycle($plan: PaymentPlans!, $deviceID: ID!) {
    UpgradeDowngradePlan(input: { plan: $plan, deviceID: $deviceID })
  }
`;

// Mutation to add/update payment info
export const UPDATE_PAYMENT_INFO = gql`
  mutation updatePaymentInfo {
    CollectPaymentInfoSession
  }
`;

// Mutation to cancel subscription
export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($ID: ID!) {
    CancelSubscription(deviceID: $ID)
  }
`;
