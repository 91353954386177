import React, { MouseEvent, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

interface ModalStyledProps {
  show: boolean;
  large: boolean;
}

interface ModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  large: boolean;
  bypass: any;
  children: any;
}

const ModalStyled = styled.div<ModalStyledProps>`
  background-color: var(--ff-bg-modal);
  color: ${({ theme }) => theme.secondary};
  transition: ${({ show }) => (show ? '0.1s ' : '0.1s  0.2s')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;

  & > div {
    transition: ${({ show }) =>
      show ? '0.2s  0.05s, background-color 0.2s linear' : '0.25s '};
    bottom: ${({ show }) => (show ? '0' : '-500px')};
    position: fixed;
    width: 100%;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;
    min-height: ${({ large }) => (large ? '500px' : '325px')};
    background-color: #f0f2f3;
    color: ${({ theme }) => theme.secondary};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    color: ${({ theme }) => theme.secondary};
  }

  form {
    p {
      margin-bottom: 0.5rem;
    }
  }

  @media (min-width: 421px) {
    justify-content: center;
    align-items: center;

    & > div {
      bottom: ${({ show }) => (show ? '30%' : '-500px')};
      left: calc(50% - 200px);
      width: 100%;
      max-width: 400px;
      border-radius: 1rem;
    }
  }
`;

const Modal = ({
  showModal,
  setShowModal,
  large,
  bypass,
  children,
}: ModalProps) => {
  const closeMenu = (event: MouseEvent) => {
    // Make sure that we are only closing when BG is clicked not menu
    if (event.target !== event.currentTarget) return;

    if (bypass) {
      bypass();
    } else {
      setShowModal(false);
    }
  };

  return (
    <ModalStyled show={showModal} large={large} onClick={closeMenu}>
      <div>{children}</div>
    </ModalStyled>
  );
};

export default Modal;
