import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BlueButton, Button } from '../styled/Button';
import Illustration from './Illustration';

const MobileHeaderStyled = styled.div`
  margin: 1rem 0;
  display: grid;
  text-align: center;
  grid-template-areas:
    'a'
    'b'
    'c'
    'd';

  svg {
    grid-area: b;
    height: 250px;
    width: 100%;
  }

  h1 {
    grid-area: a;
    line-height: 1.125;
  }

  p {
    grid-area: c;
    margin-top: 0.5rem;
    line-height: 1.5;
    max-width: 500px;
  }

  .buttons {
    grid-area: d;
  }
`;

const MobileHeader = ({ btnText, btnLink }: HomeHeaderProps) => {
  return (
    <MobileHeaderStyled>
      <h1>
        Access your coach.
        <br /> From Anywhere.
      </h1>
      <Illustration />
        <p>
          Vegatouch Cloud Services give you remote access to your connected
          coach no matter where you are. 
         <br />
            <b>
              Vegatouch cloud subscriptions can be purchased at fireflyint.com. 
              Receive a free 90-day trial with any subscription purchase. 
            </b>
        </p>
      {/* <div className="buttons">
        <Link to={btnLink}>
          <BlueButton>{btnText}</BlueButton>
        </Link>
        <Link to="/FAQ">
          <Button>FAQ</Button>
        </Link>
      </div> */}
    </MobileHeaderStyled>
  );
};

export default MobileHeader;
