import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterStyled = styled.footer`
  background-color: ${({ theme }) => theme.primary};
  border-top: 1px solid ${({ theme }) => theme.line};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;

  div {
    display: flex;

    margin: auto;

    & > :first-child {
      margin-bottom: 0.25rem;
    }
  }

  a {
    color: var(--ff-gray-6);
    text-decoration: none;
    line-height: 1.25rem;
    margin: 0 0.5rem;

    &:hover {
      color: ${({ theme }) => theme.secondary};
    }
  }
`;

const Footer = () => (
  <FooterStyled>
    <div>
      {/* <Link to="/">
        <p>Home</p>
      </Link> */}
      {/* <Link to="/support">
        <p>Support</p>
      </Link> */}
      {/* <Link to="/FAQ">
        <p>FAQ</p>
      </Link> */}

      {/* <Link to="/policies">
        <p>Policies</p>
      </Link> */}
    </div>
  </FooterStyled>
);

export default Footer;
