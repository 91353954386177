import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import styles from '../../styles/dashboard.module.scss';

interface Conditions {
  [key: string]: boolean;
}

const PasswordReqs = ({ conditions }: { conditions: Conditions }) => {
  const { length, uppercase, lowercase, number, specialChar } = conditions;

  return (
    <>
      <p>Your password must contain:</p>
      <ul className={styles.reqs}>
        <li className="no-decoration">
          <div>
            {length ? <FontAwesomeIcon icon={faCheck} color="#48bb78" /> : '•'}{' '}
          </div>
          <p>
            <span className="bold">8</span> Characters
          </p>
        </li>
        <li>
          <div>
            {uppercase ? (
              <FontAwesomeIcon icon={faCheck} color="#48bb78" />
            ) : (
                '•'
              )}{' '}
          </div>
          <p>
            One <span className="bold">Uppercase</span> Letter
          </p>
        </li>
        <li>
          <div>
            {lowercase ? (
              <FontAwesomeIcon icon={faCheck} color="#48bb78" />
            ) : (
                '•'
              )}{' '}
          </div>
          <p>
            One <span className="bold">Lowercase</span> Letter
          </p>
        </li>
        <li>
          <div>
            {number ? <FontAwesomeIcon icon={faCheck} color="#48bb78" /> : '•'}{' '}
          </div>
          <p>
            One <span className="bold">Number</span>
          </p>
        </li>
        <li>
          <div>
            {specialChar ? <FontAwesomeIcon icon={faCheck} color="#48bb78" /> : '•'}{' '}
          </div>
          <p>
            One <span className="bold">Special Character</span>
          </p>
        </li>
      </ul>
    </>
  );
};

export default PasswordReqs;
