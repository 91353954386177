import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AppliedRoute from '../components/router/AppliedRoute';
import AuthenticatedRoute from '../components/router/AuthenticatedRoute';
import UnauthenticatedRoute from '../components/router/UnauthenticatedRoute';
import CatchAllRoute from '../components/router/CatchAllRoute';
import FAQ from '../pages/FAQ';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Dashboard from '../pages/Dashboard';
import Support from '../pages/Support';
import ForgotPassword from '../pages/ForgotPassword';
import Policies from '../pages/Policies';
import { appPropsType } from '../utils/types';

const Routes = ({ appProps }: appPropsType) => {
  return (
    <Switch>
      <AppliedRoute path="/" exact component={Home} appProps={appProps} />
      {/* <AppliedRoute path="/FAQ" exact component={FAQ} appProps={appProps} />
      <AppliedRoute
        path="/policies"
        exact
        component={Policies}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login/reset"
        exact
        component={ForgotPassword}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/signup"
        exact
        component={Signup}
        appProps={appProps}
      />

      <AuthenticatedRoute
        path="/dashboard"
        exact
        component={Dashboard}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/support"
        exact
        component={Support}
        appProps={appProps}
      /> */}
      <CatchAllRoute component={NotFound} appProps={appProps} />
    </Switch>
  );
};

export default Routes;
