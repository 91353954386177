import jwt from 'jsonwebtoken';

const post = async (url: string, data: any, needsAuth?: boolean) => {
  const accessToken = await localStorage.getItem('access');

  const headers = {
    'Content-Type': 'application/json',
    authorization: `Bearer ${needsAuth && accessToken}`,
  };

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers,
    body: JSON.stringify(data),
  });

  return response;
};

const fetchAndStoreTokens = async (method: string, data: any) => {
  // Send a POST request to the server
  const response = await post(`${process.env.REACT_APP_API}/${method}`, data);

  // Check to make sure we got a solid response
  if (response.ok) {
    // Serialize data
    const data: TokenResponse = await response.json();

    // Store tokens
    localStorage.setItem('access', data.AuthenticationResult.AccessToken);
    localStorage.setItem('id', data.AuthenticationResult.IdToken);
    localStorage.setItem('refresh', data.AuthenticationResult.RefreshToken);

    // Return boolean
    return true;
  } else {
    return false;
  }
};

/**
 * Pass in the user's username and password to log them in.
 *
 * Returns an error if response.ok = false
 *
 * @param userInfo username and password
 * @returns Promise
 */
export const loginUser = async (userInfo: UserInfo) => {
  const loggedIn = await fetchAndStoreTokens('login', userInfo);

  if (loggedIn) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error('Login failed!'));
  }
};

/**
 * Pass in the user's username, password, and email to log them in.
 *
 * Returns an error if response.ok = false
 *
 * @param userInfo username, password, and name
 * @returns Promise
 */
export const signUpNewUser = async (userInfo: UserInfo) => {
  const signedUp = await fetchAndStoreTokens('signup', userInfo);

  if (signedUp) {
    return Promise.resolve();
  } else {
    return Promise.reject(new Error('Signup failed!'));
  }
};

export const getTokensFromStorage = () => {
  const idToken = localStorage.getItem('id') || null;
  const accessToken = localStorage.getItem('access') || null;
  const refreshToken = localStorage.getItem('refresh') || null;

  return { idToken, accessToken, refreshToken };
};

/**
 * Refresh a user's tokens when they expire
 *
 * Returns an error if response.ok = false
 *
 * @returns Promise
 */
export const refreshTokens = async () => {
  const refreshToken = await localStorage.getItem('refresh');

  // Send a POST request to the server
  const response = await post(`${process.env.REACT_APP_API}/refresh`, {
    token: refreshToken,
  });

  // Check to make sure we got a solid response
  if (response.ok) {
    // Serialize data
    const data: TokenResponse = await response.json();

    // Store tokens
    localStorage.setItem('access', data.AuthenticationResult.AccessToken);
    localStorage.setItem('id', data.AuthenticationResult.IdToken);

    // Return boolean
    return Promise.resolve();
  } else {
    return Promise.reject(new Error('Refresh failed!'));
  }
};

export const getUserInfo = async (): Promise<any> => {
  try {
    // Get user attribs from amplify
    const tokens: Tokens = await getTokensFromStorage();

    // Parse identity token from AWS Cognito
    const decodedJWT = await (jwt.decode(tokens.idToken as string) as {
      [key: string]: any;
    });

    // Return user's JWT
    return Promise.resolve(decodedJWT);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const requestConfirmationCode = async (email: string) => {
  try {
    // Send a POST request to the server
    const response = await post(`${process.env.REACT_APP_API}/requestReset`, {
      email,
    });

    // Check to make sure we got a solid response
    if (response.ok) {
      // Return boolean
      return Promise.resolve();
    } else {
      throw Error('Reset request failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const requestPasswordChange = async (fields: Form) => {
  try {
    // Send a POST request to the server
    const response = await post(
      `${process.env.REACT_APP_API}/forgotPassword`,
      fields
    );

    // Check to make sure we got a solid response
    if (response.ok) {
      // Return boolean
      return Promise.resolve();
    } else {
      throw Error('Forgot password failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeUserPassword = async (fields: Form) => {
  try {
    // Send a POST request to the server
    const response = await post(
      `${process.env.REACT_APP_API}/changePassword`,
      fields,
      true
    );

    // Check to make sure we got a solid response
    if (response.ok) {
      // Return boolean
      return Promise.resolve();
    } else {
      throw Error('Forgot password failed');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
