import React from 'react';
import styled from 'styled-components';

interface HeaderProps {
  title: string;
  closeable: boolean;
  callback?: () => {};
  textOverride?: string;
}

const HeaderStyled = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 600;
    color: var(--ff-gray-4);
  }

  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.cancel};
    font-size: 16px;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
`;

const Header = ({ title, closeable, callback, textOverride }: HeaderProps) => {
  return (
    <HeaderStyled>
      <h3>{title}</h3>
      {closeable && (
        <button type="button" onClick={callback}>
          {textOverride || 'Cancel'}
        </button>
      )}
    </HeaderStyled>
  );
};

export default Header;
