import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { BlueButton } from '../styled/Button';
import bucketLink from '../../utils/bucketLink';

const PopupStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  height: 250px;

  & > div {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    background-color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.line};
  }

  .policies {
    max-width: 400px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
`;

interface SetHasCookieType {
  setHasCookie: Dispatch<SetStateAction<boolean>>;
}

const Popup = ({ setHasCookie }: SetHasCookieType) => {
  const setCookiePolicy = () => {
    window.localStorage.setItem('cookiePolicy', 'true');

    setHasCookie(true);
  };

  return (
    <PopupStyled>
      <div>
        <p>
          This site uses cookies to better your experience. By continuing to use
          this website, you agree to their use.
        </p>
        <div className="policies">
          <a href={bucketLink('cookie-policy')}>Cookie Policy</a>
          <a href={bucketLink('privacy-policy')}>Privacy Policy</a>
        </div>
        <BlueButton onClick={setCookiePolicy}>I Understand</BlueButton>
      </div>
    </PopupStyled>
  );
};

export default Popup;
