import React, { useState, useEffect, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { ThemeProvider } from 'styled-components';

import createApolloClient from '../utils/createApolloClient';
import GlobalStyles from './GlobalStyles';
import Routes from '../router/Routes';
import Modal from '../components/modal/Modal';
import UserProfile from '../components/modal/ManageUserMenu';
import { lightTheme } from '../components/styled/System';
import Popup from '../components/popup/Popup';
import { getTokensFromStorage } from '../utils/auth';

const App = ({ history }: RouteComponentProps): ReactElement<any, any> => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLarge, setShowLarge] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [client, setClient] = useState<null | ApolloClient<any>>(null);

  useEffect(() => {
    // Run auth check on load
    onLoad();
  }, []);

  useEffect(() => {
    if (window.localStorage.getItem('cookiePolicy')) setHasCookie(true);
  }, []);

  const onLoad = async () => {
    try {
      // Get tokens from current user
      const tokens: Tokens = getTokensFromStorage();

      // Connection to GraphQL and set in state
      await setClient(createApolloClient(tokens));

      // Check to see if user had existing tokens
      if (tokens.accessToken && tokens.idToken) userHasAuthenticated(true);
    } catch (e) {
      alert(e.message);
    }

    // Show app
    setIsAuthenticating(false);
  };

  const handleLogout = async () => {
    // Make sure modal is closes
    setShowModal(false);

    // Clear local storage
    localStorage.removeItem('access');
    localStorage.removeItem('id');

    // Stop Apollo client
    await client?.stop();

    // Clear Apollo cache and store
    await client?.clearStore();

    // Set state to false
    userHasAuthenticated(false);

    // Redirect to login page
    history.push('/login');
  };

  const appProps = {
    isAuthenticated,
    userHasAuthenticated,
    handleLogout,
    setShowModal,
    client,
  };

  return (
    <>
      {!isAuthenticating && (
        <ApolloProvider client={client as any}>
          <ThemeProvider theme={lightTheme}>
            <GlobalStyles />
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              large={showLarge}
              bypass={false}
            >
              <UserProfile
                handleLogout={handleLogout}
                setShowModal={setShowModal}
                setShowLarge={setShowLarge}
              />
            </Modal>
            <div
              style={{
                position: showModal ? 'fixed' : 'inherit',
                width: '100%',
              }}
            >
              <Routes appProps={appProps} />
            </div>
            {!hasCookie && <Popup setHasCookie={setHasCookie} />}
          </ThemeProvider>
        </ApolloProvider>
      )}
    </>
  );
};

export default withRouter(App);
