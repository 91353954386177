import { useEffect, useState, ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { verifyModule } from '../utils/module-id-regex';
import { Form, ModuleDetails, Merge } from '../utils/types';
import { returnPricing } from '../utils/subscription';

/**
 * Custom hooks for input fields.
 * @param initialState initialState for Input Fields
 */
export const useFormFields = (
  initialState: Form
): [
  Form,
  (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
] => {
  // Initial use state
  const [fields, setValues] = useState(initialState);

  // Properties returned from hook
  return [
    fields,
    function (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
      const target = event?.target;

      setValues({ ...fields, [target?.id]: target?.value });
    },
  ];
};

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

type DeviceDetails = Merge<ModuleDetails, Pricing>;

export const useDeviceDetails = (moduleID: string): DeviceDetails => {
  if (!verifyModule(moduleID)) throw new Error('Missing module ID');

  const moduleIDSplit = moduleID.split('-');

  const pricing = returnPricing(moduleIDSplit[0]);

  return {
    ...pricing,
    typeAbv: moduleIDSplit[0],
    number: moduleIDSplit[1],
    moduleID,
  };
};

export const useStatus = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const setStatus = (field: string, value: boolean) => {
    if (field === 'loading') setLoading(value);
    if (field === 'success') setSuccess(value);
  };

  return { loading, success, setStatus };
};

export const useDarkMode = () => {
  const [theme, setTheme] = useState('light');
  const [componentMounted, setComponentMounted] = useState(false);
  const setMode = (mode: string) => {
    window.localStorage.setItem('theme', mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (theme === 'light') {
      setMode('dark');
    } else {
      setMode('light');
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches &&
    !localTheme
      ? setMode('dark')
      : localTheme
      ? setTheme(localTheme)
      : setMode('light');
    setComponentMounted(true);
  }, []);

  return [theme, toggleTheme, componentMounted];
};

export const useResponsive = () => {
  const isDesktop = useMediaQuery({ query: '(min-device-width: 770px)' });
  const isTablet = useMediaQuery({
    query: '(min-device-width: 422px) and ( max-device-width: 769px)',
  });
  const isPhone = useMediaQuery({ query: '(max-device-width: 421px)' });

  return {
    isDesktop,
    isTablet,
    isPhone,
  };
};
