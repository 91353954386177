import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { Page } from '../components/styled/Page';
import { Button } from '../components/styled/Button';
import Illustration from '../components/notFound/Illustration';

const NotFoundStyled = styled(Page)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  max-width: 1000px;
  height: calc(100vh - 116px);
  width: 100%;
  margin: auto;

  .content {
    padding: 1rem 0;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      height: 180px;
      width: 200px;
    }

    p {
      text-align: center;
      color: var(--ff-gray-6);
      margin-bottom: 1rem;
    }

    button {
      margin-top: 1rem;
    }
  }

  /* Adds side padding right before it hits the full width */
  @media (max-width: 1005px) {
    .content {
      padding: 1rem;
    }
  }
`;

const NotFound = ({ history }: RouteComponentProps) => {
  return (
    <NotFoundStyled>
      <div className="content">
        <Illustration />
        <h3>Sorry, page not found!</h3>
        {/* <Button onClick={() => history.push('/dashboard')}>Dashboard</Button> */}
      </div>
    </NotFoundStyled>
  );
};

export default NotFound;
