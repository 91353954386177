import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Page } from '../components/styled/Page';
import Footer from '../components/footer/Footer';
import { getTokensFromStorage } from '../utils/auth';
import { useResponsive } from '../hooks/hookslib';
import MobileHeader from '../components/home/MobileHeader';
import DesktopHeader from '../components/home/DesktopHeader';
import TabletHeader from '../components/home/TabletHeader';

const HomeStyled = styled(Page)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  .container {
    min-height: calc(100vh - 116px);
    width: 100%;
    padding: 1rem 0;

    a button {
      margin: 0.5rem 0;
    }
  }

  .content {
    max-width: 1000px;
    width: 100%;
    text-align: left;

    a {
      text-decoration: none;
      font-weight: 700;
      color: var(--ff-blue-2);
    }
  }

  /* Adds side padding right before it hits the full width */
  @media (max-width: 1005px) {
    .container {
      padding: 1rem;
    }
  }
`;

const Home = ({ appProps }: AppPropsType) => {
  const [getStartedRedirect, setGetStartedRedirect] = useState('/signup');
  const { isDesktop, isTablet, isPhone } = useResponsive();

  // Destructure app props
  const { isAuthenticated } = appProps;

  useEffect(() => {
    (async () => {
      try {
        const { refreshToken } = await getTokensFromStorage();

        if (refreshToken) setGetStartedRedirect('/login');
      } catch {
        // Do nothing
      }
    })();
  }, []);

  // Change vars based on auth state
  const btnLink = isAuthenticated ? '/dashboard' : getStartedRedirect;
  const btnText = isAuthenticated ? 'Dashboard' : 'Get Started';

  return (
    <HomeStyled>
      <div className="container">
        <div className="content">
          {isPhone && <MobileHeader btnLink={btnLink} btnText={btnText} />}
          {isTablet && <TabletHeader btnLink={btnLink} btnText={btnText} />}
          {isDesktop && <DesktopHeader btnLink={btnLink} btnText={btnText} />}
        </div>
      </div>
      <Footer />
    </HomeStyled>
  );
};

export default Home;
