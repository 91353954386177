import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ManageUser from './ManageUser';
import ChangePassword from './ChangePassword';
import Loading from './Loading';
import Success from './Success';
import { useStatus } from '../../hooks/hookslib';

const ManageUserMenu = ({ setShowModal, handleLogout, setShowLarge }) => {
  const history = useHistory();
  const { loading, success, setStatus } = useStatus();
  const [changePassword, setChangePassword] = useState(false);

  const handleDashboard = () => {
    // Redirect to dashboard
    history.push('/dashboard');

    // Hide modal
    setShowModal(false);
  };

  const showChangePassword = bool => {
    if (bool) {
      setShowLarge(bool);
      setTimeout(() => setChangePassword(bool), 200);
    } else {
      setChangePassword(bool);
      setShowLarge(bool);
    }
  };

  if (loading) return <Loading />;
  if (success) return <Success callback={() => setStatus('success', false)} />;

  return (
    <>
      {changePassword ? (
        <ChangePassword
          showChangePassword={showChangePassword}
          setStatus={setStatus}
        />
      ) : (
        <ManageUser
          handleDashboard={handleDashboard}
          handleLogout={handleLogout}
          showChangePassword={showChangePassword}
          setShowModal={setShowModal}
          setStatus={setStatus}
          setShowLarge={setShowLarge}
        />
      )}
    </>
  );
};

export default ManageUserMenu;
