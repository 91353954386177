import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';
import App from './app/App';
import config from './config';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// Makes it easier to differentiate dev vs prod tabs
if (process.env.NODE_ENV === 'development')
  document.title = 'DEV: Vegatouch Cloud Services';

serviceWorker.unregister();
