const palette = {
  black: 'var(--ff-black)',
  smoke: 'var(--ff-smoke)',
  charcoal: 'var(--ff-charcoal)',
  gray1: 'var(--ff-gray-1)',
  gray2: 'var(--ff-gray-2)',
  gray3: 'var(--ff-gray-3)',
  gray4: 'var(--ff-gray-4)',
  gray5: 'var(--ff-gray-5)',
  gray6: 'var(--ff-gray-6)',
  gray7: 'var(--ff-gray-7)',
  snow: 'var(--ff-snow)',
  white: 'var(--ff-white)',
  transparent: 'var(--ff-transparent)',
  blue1: 'var(--ff-blue-1)',
  blue2: 'var(--ff-blue-2)',
  green: 'var(--ff-green)',
  greenDark: 'var(--ff-green-dark)',
  greenTrans: 'var(--ff-green-trans)',
  yellow: 'var(--ff-yellow)',
  yellowDark: 'var(--ff-yellow-dark)',
  yellowTrans: 'var(--ff-yellow-trans)',
  red: 'var(--ff-red)',
  redDark: 'var(--ff-red-dark)',
  redTrans: 'var(--ff-red-trans)',
};

export const darkTheme = {
  primary: palette.smoke,
  secondary: palette.snow,
  cancel: palette.blue2,
  line: palette.gray3,
  device: palette.gray1,
  isDark: true,
  ...palette,
};

export const lightTheme = {
  primary: palette.snow,
  secondary: palette.smoke,
  cancel: palette.blue1,
  line: palette.transparent,
  device: palette.white,
  ...palette,
};

export const spacing = [4, 8, 12, 16, 20, 24, 32, 40, 48, 64];
