import React, { useEffect, useState, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  faUserCircle,
  faArrowCircleRight,
} from '@fortawesome/free-solid-svg-icons';

import { appPropsType } from '../../utils/types';
import { getUserInfo } from '../../utils/auth';

const NavbarStyled = styled.nav`
  background-color: var(--ff-smoke);
  border-bottom: 1px solid ${({ theme }) => theme.line};
  padding: 1rem;
  position: sticky;
  z-index: 5;
  top: 0;

  .container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .nav-right {
      color: var(--ff-white);
      text-align: end;
      text-decoration: none;
      font-weight: 700;
      cursor: pointer;
    }
  }

  span {
    display: flex;
    align-items: center;

    h5 {
      font-weight: 500;
      margin-right: 5px;
    }

    &:hover {
      transition: all 0.2s ease;
      filter: brightness(80%);
    }

    a {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  a {
    color: var(--ff-white);
    text-decoration: none;

    /* top left 'Vegatouch' link */
    &[href='/'] {
      font-weight: 700;
    }

    &:hover {
      transition: all 0.2s ease;
      filter: brightness(80%);
    }
  }
`;

const NavBar = ({ appProps }: appPropsType) => {
  const [name, setName] = useState('');
  const { isAuthenticated, setShowModal } = appProps;

  useEffect(() => {
    onLoad();
  }, [isAuthenticated]);

  const onLoad = async () => {
    try {
      // Get user attribs
      const { name } = await getUserInfo();

      // Set user's name
      setName(name);
    } catch (error) {
      setName('');
    }
  };

  // A11y support
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') setShowModal(true);
  };

  return (
    <NavbarStyled>
      <div className="container">
        <Link to="/">
          <h3>Vegatouch</h3>
        </Link>
        {/* <div className="nav-right">
          {isAuthenticated ? (
            <span
              onClick={() => setShowModal(true)}
              onKeyDown={handleKeyPress}
              tabIndex={0}
              role="button"
            >
              <h5>{name}</h5>
              <FontAwesomeIcon icon={faUserCircle} />
            </span>
          ) : (
            <span>
              <Link to="/login">
                <FontAwesomeIcon icon={faArrowCircleRight} />
                <h5>Sign In</h5>
              </Link>
            </span>
          )}
        </div> */}
      </div>
    </NavbarStyled>
  );
};

export default NavBar;
