import styled from 'styled-components';

export const Page = styled.main`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secondary};
  height: calc(100% - 57px);
`;

export const Dashboard = styled(Page)`
  & > div {
    height: 100%;
    overflow-y: auto;
  }

  .container {
    padding: 1rem 2rem;
    min-height: calc(100vh - 116px);
    height: auto;
  }
`;
